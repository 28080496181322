import {ApiService} from '@/services/ApiService'

const URL = '/api/vessel-owner'

class VesselownerService extends ApiService {
    constructor(){
        super()
        super.hasRelation = true;
    }
    getUrl(){
        return URL
    }
}

export default new VesselownerService()