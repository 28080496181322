<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name', 'code', 'imo', 'ramp_capacity', 'entry_height']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="code" :header="$t('overview.code')" style="width: 20%"></Column>
            <Column field="imo" header="IMO" style="width: 30%"></Column>
            <Column field="ramp_capacity" :header="$t('overview.ramp_capacity')" style="width: 10%"></Column>
            <Column field="entry_height" :header="$t('overview.entry_height')" style="width: 10%"></Column>
        </template>

        <template #form>
            <VesselForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import VesselForm from '@/components/vessel/VesselForm.vue';
import vesselService from '@/services/VesselService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        VesselForm
    },
    data() {
        return {
            item: {},
            itemService: vesselService,
            itemFormRef: null
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>