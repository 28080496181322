<template v-if="formActive">
    <ul class="nav">
        <li class="nav-item" role="presentation">
            <button class="nav-link d-none active" id="main-inv-tab" data-bs-toggle="tab" data-bs-target="#main" type="button" role="tab" aria-controls="main" aria-selected="true"></button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">

    <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
        <div class="row mb-2">
            <div class="col-4 col-form-label-sm required-label">
                {{ `IMO ` + $t('overview.number') }}
            </div>
            <div class="col-8">
                <input class="form-control form-control-sm license-plate" v-model="vessel.imo" @keydown="preventNonNumbers($event, false, false)" @change="checkIMONr" required="" />
                <span id="imocheck" class="text-danger col-form-label-sm d-none"> </span>
            </div>
        </div>
        <FormItem :label="`MMSI ` + $t('overview.number')" v-model="vessel.mmsi" />
        <FormItem :label="$t('overview.name')" v-model="vessel.name" :required="true" />
        <FormItem :label="$t('overview.code')" v-model="vessel.code" :required="true" />
        <FormItem :label="$t('overview.flag')" type="livesearch" id="flag" v-model="vessel.flag_country_id" :required="true" :options="countries" :content="vessel.flag_country ? vessel.flag_country.name : ''" />
        <FormItem :label="$t('navigation.vessel_owners')" type="livesearch" id="owner" v-model="vessel.vessel_owner_id" :required="true" :service="vesselownerService" :content="vessel.vessel_owner ? vessel.vessel_owner.relation.name : ''" nameField='name' />
        <FormItem :label="$t('overview.ramp_capacity')" type="number-with-tags" :allowCommas="false" v-model="vessel.ramp_capacity" endTag="ts" sizeItem="2" />
        <FormItem :label="$t('overview.entry_height')" type="number-with-tags" :allowCommas="false" v-model="vessel.entry_height" endTag="cm" sizeItem="2" />
        <FormItem label="TEU" type="number" v-model="vessel.teu" sizeItem="2" />
    </div>

    </div>
</template>

<script>
    import vesselownerService from '@/services/VesselownerService'
    import fileService from "@/services/FileService"
    import dataStore from '@/store/data'
    // import Files from '@/components/formpopup/Files'
    
    export default {
        name: 'VesselForm',
        props: ['itemData'],
        components: {
            // Files
        },
        data() {
            return {
                vessel: {},
                relations:[],
                flags: [],
                vesselowners: [],
                submitted: false,
                loading: false,
                invalid: {},
                isEditing: false,
                countries: [],
                vesselownerService: vesselownerService,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.vessel = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let vessel = this.vessel;
                let valid = true;
                let invalidMessage = this.$t('errors.empty_fields');

                let required = document.querySelectorAll('#formModal input[required]');

                for(let field of required) {
                    if(field.classList.contains("license-plate") && field.classList.contains("is-invalid")) {
                        valid = false;
                        invalidMessage = "The IMO number is invalid.";
                    }
                    else if(!field.value || field.value == this.$t('form.select') || (field.classList.contains("simple-typeahead-input") && field.classList.contains("is-invalid"))) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = vessel.name && vessel.code && vessel.flag_country_id && vessel.vessel_owner_id;
                if(!valid) alert(invalidMessage);
                return valid;
            },
            getData() {
                return this.vessel;
            },
            setData(data) {
                this.vessel = data;
                this.$nextTick(() => {
                    let checktext = document.getElementById('imocheck');
                    if(checktext){
                        checktext.classList.add('d-none');
                        checktext.previousElementSibling.classList.remove('is-invalid');
                    }
                });
            },
            reset() {
                this.vessel = {
                    'file_info': [],
                    'name': null,
                    'code': null,
                    'imo': null,
                    'flag_country_id': null,
                    'vessel_owner_id': null,
                    'ramp_capacity': null,
                    'entry_height': null,
                    'teu': null,
                    'mmsi': null
                }
                this.$forceUpdate();
            },
            checkIMONr(e) {
                var imo = e.target.value;
                var resultElement = e.target.nextElementSibling;
                var resultText = this.$t('errors.container_check');
                var sum = 0;
                var checksum = "Unknown";
                var chars = imo.split("");
                if (imo.length == 7) {
                    for (var i = 0; i < 6; i++) {
                        var n = new Number(chars[i]);
                        sum += n * (7 - i);
                    }
                    checksum = imo.charAt(6);
                }
                else{
                    resultText = "IMO must be 7 characters long.";
                }
                
                if (checksum == (sum % 10)) {
                    e.target.classList.remove('is-invalid');
                    resultElement.classList.add('d-none');
                } else {
                    e.target.classList.add('is-invalid');
                    resultElement.classList.remove('d-none');
                }
                resultElement.innerHTML = resultText;
            },
            addFiles(fileInfo){
                if (this.vessel.id) {
                    fileService.upload({"file_info": [fileInfo],"id": this.vessel.id,"model": 'vessel'}).then(response => {
                        this.vessel.file_info.unshift(response.data); 
                    }).catch(error => {
                        console.log('error', error)
                    })
                } else {
                this.vessel.file_info.unshift(fileInfo); 
                }

            }
        },
        mounted() {
            //this.reset()
            this.countries = dataStore.getters.getCountryNames;
        }
    }
</script>